import React, { useState, useMemo, useCallback } from 'react';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

// constants
import { TECH_MENU } from '../../constants/menuList';
import {
  CMS_TECH,
  UI_UX_TECH,
  MOBILE_TECH,
  BACKEND_TECH,
  FRONTEND_TECH,
  DATABASE_TECH
} from '../../constants/data';

const TechStack = () => {
  const [isActive, setIsActive] = useState('Frontend');
  const [lastClicked, setLastClicked] = useState('down'); // Track last clicked arrow

  const activeIndex = useMemo(
    () => TECH_MENU.findIndex(menu => menu.text === isActive),
    [isActive]
  );
  const handleClickMenu = useCallback(menuText => {
    setIsActive(menuText);
  }, []);

  const handleClickUp = () => {
    const previousIndex =
      (activeIndex - 1 + TECH_MENU.length) % TECH_MENU.length;
    setIsActive(TECH_MENU[previousIndex].text);
    setLastClicked('up');
  };

  const handleClickDown = () => {
    const nextIndex = (activeIndex + 1) % TECH_MENU.length;
    setIsActive(TECH_MENU[nextIndex].text);
    setLastClicked('down');
  };

  const getButtonClasses = direction => {
    return `px-4 py-3 rounded flex justify-center items-center transition-colors duration-300 ${
      lastClicked === direction ? 'bg-white text-black' : ''
    }`;
  };

  const renderTechIcons = useCallback(
    techList => (
      <div className="max-w-[1020px] mx-auto flex flex-wrap items-center justify-center gap-6 sm:gap-8 xl:gap-12">
        {techList.map(tech => (
          <div
            key={tech.id}
            className="w-16 h-16 sm:w-24 sm:h-24 md:28 lg:w-32  relative group"
          >
            <Image
              src={tech.logo}
              alt={tech.name}
              width={tech.width || 100}
              className="aspect-[3/2] object-contain"
            />
            <div className="w-28 sm:w-32 text-center absolute -top-10 -left-7 sm:-left-4 px-3 py-2 bg-gray-800 text-white text-xs sm:text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              {tech.name}
            </div>
          </div>
        ))}
      </div>
    ),
    [isActive]
  );

  const desktopContent = (
    <div className="hidden md:block max-w-[1480px] mx-auto py-16 lg:py-32 2xl:h-screen relative">
      <h2 className="text-center font-bold text-3xl lg:text-5xl leading-[40px] lg:leading-[73px] mb-8 lg:mb-12">
        Our Tech Stack<span className="text-primary">.</span>
      </h2>
      <div className="w-full mb-12 lg:mb-24 ">
        <ul className="flex flex-row flex-wrap justify-center list-none">
          {TECH_MENU.map(menu => (
            <li
              onClick={() => handleClickMenu(menu.text)}
              key={menu.text}
              className={`text-white font-semibold text-lg lg:text-2xl px-4 lg:px-6 relative cursor-pointer ${
                isActive === menu.text &&
                'after:absolute after:left-4 lg:after:left-6 after:top-8 lg:after:top-10 after:rounded after:w-[30px] lg:after:w-[41px] after:h-1 after:bg-primary'
              }`}
            >
              {menu.text}
              <span
                className={
                  isActive === menu.text ? 'text-white' : 'text-primary'
                }
              >
                .
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="relative container mx-auto">
        {renderTechIcons(isActive === 'Frontend' ? FRONTEND_TECH : [])}
        {renderTechIcons(isActive === 'Backend' ? BACKEND_TECH : [])}
        {renderTechIcons(isActive === 'Databases' ? DATABASE_TECH : [])}
        {renderTechIcons(isActive === 'CMS' ? CMS_TECH : [])}
        {renderTechIcons(isActive === 'Mobile Application' ? MOBILE_TECH : [])}
        {renderTechIcons(isActive === 'UI/UX' ? UI_UX_TECH : [])}
      </div>
      <div className="hidden 2xl:flex absolute inset-y-1/4 right-0 flex-col justify-center">
        <div className="mb-10">
          <button className={getButtonClasses('up')} onClick={handleClickUp}>
            <FontAwesomeIcon icon={faArrowUp} size="xs" className="w-4" />
          </button>
        </div>
        <div>
          <button
            className={getButtonClasses('down')}
            onClick={handleClickDown}
          >
            <FontAwesomeIcon icon={faArrowDown} size="xs" className="w-4" />
          </button>
        </div>
      </div>
    </div>
  );

  const mobileContent = (
    <div className="w-[90%] mx-auto py-12 md:hidden">
      <h2 className="text-center font-semibold sm:ont-bold text-2xl leading-[26px] mb-6">
        Our Tech Stack<span className="text-primary">.</span>
      </h2>
      <div className="mb-8">
        <ul className="flex flex-row flex-wrap justify-center list-none">
          {TECH_MENU.map(menu => (
            <li
              onClick={() => setIsActive(menu.text)}
              key={menu.text}
              className={`text-white font-semibold text-[12px] leading-[14px] mb-6 pr-4 relative cursor-pointer ${
                isActive === menu.text &&
                'text-primary after:absolute after:left-0 after:top-4 after:rounded after:w-[29px] after:h-[2px] after:bg-primary'
              }`}
            >
              {menu.text}
              <span
                className={
                  isActive === menu.text ? 'text-white' : 'text-primary'
                }
              >
                .
              </span>
            </li>
          ))}
        </ul>
      </div>
      {renderTechIcons(isActive === 'Frontend' ? FRONTEND_TECH : [])}
      {renderTechIcons(isActive === 'Backend' ? BACKEND_TECH : [])}
      {renderTechIcons(isActive === 'Databases' ? DATABASE_TECH : [])}
      {renderTechIcons(isActive === 'CMS' ? CMS_TECH : [])}
      {renderTechIcons(isActive === 'Mobile Application' ? MOBILE_TECH : [])}
      {renderTechIcons(isActive === 'UI/UX' ? UI_UX_TECH : [])}
    </div>
  );

  return (
    <section className="bg-gray-darker text-white mt-12 sm:mt-14 md:mt-40">
      {desktopContent}
      {mobileContent}
    </section>
  );
};

export default TechStack;
